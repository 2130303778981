// extracted by mini-css-extract-plugin
export var skillLevelColorUnknown = "gray";
export var skillLevelColorNovice = "#83c466";
export var skillLevelColorProficient = "#4b9acf";
export var skillLevelColorExpert = "#5c00a9";
export var themePrimaryBase = "#1f2522";
export var themePrimaryLight = "#36413c";
export var themePrimaryDark = "#080909";
export var themeAccentBase = "#199496";
export var themeAccentLight = "#20bfc2";
export var themeAccentDark = "#12696a";
export var themeSecondaryBase = "#d9b08c";
export var themeSecondaryLight = "#e6cab2";
export var themeSecondaryDark = "#cc9666";
export var themeForegroundBase = "#fff";
export var themeForegroundLight = "#fff";
export var themeForegroundDark = "#e6e6e6";
export var themeBackgroundBase = "#1f2522";
export var themeBackgroundLight = "#36413c";
export var themeBackgroundDark = "#080909";
export var contrastDarkPrimary = "#f0efef";
export var contrastDarkSecondary = "rgba(240,239,239,0.7)";
export var contrastDarkDisabled = "rgba(240,239,239,0.5)";
export var contrastDarkHint = "rgba(240,239,239,0.12)";
export var contrastLightPrimary = "rgba(0,0,0,0.87)";
export var contrastLightSecondary = "rgba(0,0,0,0.54)";
export var contrastLightDisabled = "rgba(0,0,0,0.4)";
export var contrastLightHint = "rgba(0,0,0,0.12)";
export var phoneSmall = "320px";
export var phone = "480px";
export var tablet = "768px";
export var laptop = "900px";
export var desktop = "1024px";
export var desktopLarge = "1200px";
export var ShowcaseModal = "ShowcaseModalContent-module--ShowcaseModal--3rIRh";