import { toKeyValue } from '../toKeyValue';

/**
 * Returns an array containing local and global classnames based on the given styles object and globalKey
 * @param styles - The styles object, most likely generated by css modules
 * @param globalKey - The global key, or global classname
 * @returns Array of two strings: [localClassname, globalClassname]
 */
export function createGlobalLocalClassnames(
  styles: Record<string, string>,
  globalKey: string,
): [string, string] {
  const { key: globalClassname, value: localClassname } = toKeyValue(
    globalKey,
    styles,
  );
  return [localClassname, globalClassname];
}
